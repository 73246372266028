import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "modifiersAdd": {
      "id": "modifier_groups.add",
      "defaultMessage": "Add Modifier Group"
    },
    "modifiersCreate": {
      "id": "modifier_groups.create",
      "defaultMessage": "Create Modifier Group"
    },
    "modifiersDestroy": {
      "id": "modifier_groups.destroy",
      "defaultMessage": "Remove Modifier Group"
    },
    "modifiersDestroyConfirm": {
      "id": "modifier_groups.destroy_confirm",
      "defaultMessage": "Are you sure want to remove this modifier group?"
    },
    "modifiersDestroyConfirmTitle": {
      "id": "modifier_groups.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "modifiersNew": {
      "id": "modifier_groups.new",
      "defaultMessage": "New Modifier Group"
    },
    "modifiersCopy": {
      "id": "modifier_groups.copy",
      "defaultMessage": "Copy to new modifier group"
    },
    "modifiersEdit": {
      "id": "modifier_groups.edit",
      "defaultMessage": "Edit Modifier Group"
    },
    "modifiersEmpty": {
      "id": "modifier_groups.empty",
      "defaultMessage": "Create modifiers group to customize items and provide additional choices for online ordering.",
    },
    "modifiersIndex": {
      "id": "modifier_groups.index",
      "defaultMessage": "Modifiers"
    },
    "modifiersManageOnSyncing": {
      "id": "modifier_groups.manage_on_syncing",
      "defaultMessage": "Manage OrderNerd Sync"
    },
    "modifiersOutOfStock": {
      "id": "modifier_groups.out_of_stock",
      "defaultMessage": "Modifiers out of stock"
    },
    "modifiersOpenRootGroup": {
      "id": "modifier_groups.open_root_group",
      "defaultMessage": "Open Root Group"
    },
    "modifiersRemoveGroup": {
      "id": "modifier_groups.remove_group",
      "defaultMessage": "Remove Group"
    },
    "modifiersDatatableTitle": {
      "id": "modifier_groups.datatable_title",
      "defaultMessage": "Modifier Groups"
    },
    "buildingModifierGroupsModalTitle": {
      "id":"modifier_groups.build.title",
      "defaultMessage": "Building Modifier Groups"
    },
    "buildingModifierGroupsModalLearnMore": {
      "id":"modifier_groups.build.learn_more_link",
      "defaultMessage": "Learn More"
    },
    "modifiers.connectModifierGroupHeader": {
      "id": "modifier_groups.connect_modifier_group_header",
      "defaultMessage": "Select a Modifier Group"
    },
    "modifiers.connectModifierGroupTitle": {
      "id": "modifier_groups.connect_modifier_group_title",
      "defaultMessage": "Select to add modifier groups to &ldquo;{item_name}&rdquo;"
    },
    "modifiers.connectModifierGroupSelectedCount": {
      "id": "modifier_groups.connect_modifier_group_selected_count",
      "defaultMessage": "{selected_modifier_group_count}/{modifier_group_count} modifier groups selected"
    },
  }
);
export default defaultMessages;
