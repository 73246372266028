import { createConsumer } from '@rails/actioncable';
import { InMemoryCache } from '~/lazy_apollo/client';

import { ApolloClient, from, split, createHttpLink } from '~/lazy_apollo/client';
import { getMainDefinition } from '~/lazy_apollo/client/utilities';

import ActionCableLink from './ActionCableLink';

import schema from '../libs/gql/schema/interfaces.json';
import operationStoreClient from './operationStoreClient';
import { initApolloLogsWithTestData } from './testDataLogs';
import { mergeAdminMenuSubsectionItems, mergeMenuItemCartsByLocationIdRecords } from './mergePaginatedHandlerRecords';

export const initApolloWithWebsockets = ({ url, operationStoreEnabled, websocketUrl = null, channelName = null }) => {
  // Attempt to connect to shared client initialized by another Popmenu instance
  if (window.POPMENU_CLIENT) {
    console.log('[POPMENU] Connecting to shared client...');
    return window.POPMENU_CLIENT;
  }

  // Initialize cache w/ fragment data to handle interfaces
  // https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
  const possibleTypes = {};
  schema.data.__schema.types.forEach((supertype) => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map(subtype => subtype.name);
    }
  });

  const typePolicies = {
    Query: {
      fields: {
        adminMenuSubsectionItems: {
          keyArgs: ['sectionId', 'subsectionId'],
          merge: mergeAdminMenuSubsectionItems,
        },
        menuItemCartsByLocationId: {
          keyArgs: ['cartType', 'locationId', 'cartBucket', 'startDate', 'endDate', 'filterBy', 'paymentCaptured', 'searchTerm', 'pagination', ['sortBy', 'sortDir']],
          merge: mergeMenuItemCartsByLocationIdRecords,
        },
      },
    },
  };

  const cache = new InMemoryCache({ possibleTypes, typePolicies });

  // apollo-link
  const links = [];

  if (operationStoreEnabled && operationStoreClient) {
    links.push(operationStoreClient.apolloLink);
  }

  if (process.env.LOG_TEST_DATA_TO_BROWSER_CONSOLE) {
    initApolloLogsWithTestData(links);
  }

  links.push(
    createHttpLink({
      credentials: 'include',
      uri: url,
    }),
  );

  const httpLink = from(links);

  let link;
  if (websocketUrl) {
    // Client-side websocket adapter
    const cable = createConsumer(websocketUrl);
    const wsLink = new ActionCableLink({ cable, channelName });
    // Inspect query to determine appropriate adapter
    link = split(
      // Split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    );
  } else {
    link = httpLink;
  }

  // Initialize client
  console.log('[POPMENU] Init Popmenu Apollo Client');
  const client = new ApolloClient({
    cache,
    link,
    ssrMode: false,
  });
  window.POPMENU_CLIENT = client;
  return client;
};
