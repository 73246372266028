import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "frontOfHouseIndexTitle": {
      "id": "front_of_house.index.title",
      "defaultMessage": "Front of House"
    },
    "frontOfHouseSettingsTitle": {
      "id": "front_of_house.settings.title",
      "defaultMessage": "Front of House Settings"
    },
    "frontOfHouseSettingsTitleBrief": {
      "id": "front_of_house.settings.title_brief",
      "defaultMessage": "Settings"
    },
    "frontOfHouseSettingsUpdated": {
      "id": "front_of_house.settings.updated",
      "defaultMessage": "Updated settings for"
    },
    "frontOfHouseSettingsReservationIntervalDropdownTitle": {
      "id": "front_of_house.settings.reservation_interval_dropdown_title",
      "defaultMessage": "Interval"
    },
    "frontOfHouseSettingsGenericError": {
      "id": "front_of_house.generic_error",
      "defaultMessage": "Something went wrong with your request."
    },
    "frontOfHouseSettingsReservationsTab": {
      "id": "front_of_house.settings.reservations.tab",
      "defaultMessage": "Reservations"
    },
    "frontOfHouseSettingsWaitlistTab": {
      "id": "front_of_house.settings.waitlist.tab",
      "defaultMessage": "Waitlist"
    },
    "frontOfHouseWaitlistActionButtonCheckin": {
      "id": "front_of_house.waitlist.action_button.checkin",
      "defaultMessage": "Check-in"
    },
    "frontOfHouseWaitlistActionButtonNotify": {
      "id": "front_of_house.waitlist.action_button.notify",
      "defaultMessage": "Notify"
    },
    "frontOfHouseWaitlistActionButtonSeat": {
      "id": "front_of_house.waitlist.action_button.seat",
      "defaultMessage": "Seat"
    },
    "frontOfHouseWaitlistActionButtonReAdd": {
      "id": "front_of_house.waitlist.action_button.readd",
      "defaultMessage": "Re-Add"
    },
    "frontOfHouseWaitlistAddButton": {
      "id": "front_of_house.waitlist.add_button",
      "defaultMessage": "Add To Waitlist"
    },
    "frontOfHouseReservationsAddButton": {
      "id": "front_of_house.reservations.add_button",
      "defaultMessage": "Add Reservation"
    },
    "frontOfHouseReservationsActionButtonReject": {
      "id": "front_of_house.reservations.action_button.reject",
      "defaultMessage": "Reject"
    },
    "frontOfHouseReservationsActionButtonAccept": {
      "id": "front_of_house.reservations.action_button.accept",
      "defaultMessage": "Accept"
    },
    "frontOfHouseReservationsActionButtonSeat": {
      "id": "front_of_house.reservations.action_button.seat",
      "defaultMessage": "Seat"
    },
    "frontOfHouseReservationsActionButtonUnseat": {
      "id": "front_of_house.reservations.action_button.unseat",
      "defaultMessage": "Unseat"
    },
    "frontOfHouseWaitlistAverageWaitTimeError": {
      "id": "front_of_house.waitlist.average_wait_time_error",
      "defaultMessage": "Error saving average wait time"
    },
    "frontOfHouseWaitlistAverageWaitTimeUpdated": {
      "id": "front_of_house.waitlist.average_wait_time_updated",
      "defaultMessage": "Updated average wait time!"
    },
    "frontOfHouseWaitlistNotifyModalButton": {
      "id": "front_of_house.waitlist.notify_modal.button",
      "defaultMessage": "Send"
    },
    "frontOfHouseWaitlistNotifyModalDescription": {
      "id": "front_of_house.waitlist.notify_modal.description",
      "defaultMessage": "Notify your guest with the status of their table."
    },
    "frontOfHouseWaitlistNotifyModalNextDescription": {
      "id": "front_of_house.waitlist.notify_modal.next.description",
      "defaultMessage": "Your party is next on the list. Please return to the restaurant. Your table will be ready shortly."
    },
    "frontOfHouseWaitlistNotifyModalNextTitle": {
      "id": "front_of_house.waitlist.notify_modal.next.title",
      "defaultMessage": "You're next on the list"
    },
    "frontOfHouseWaitlistNotifyModalReadyDescription": {
      "id": "front_of_house.waitlist.notify_modal.ready.description",
      "defaultMessage": "Your table is ready! Come to the front and meet with your host/ess to be seated."
    },
    "frontOfHouseWaitlistNotifyModalReadyTitle": {
      "id": "front_of_house.waitlist.notify_modal.ready.title",
      "defaultMessage": "Table is Ready"
    },
    "frontOfHouseWaitlistCheckInModalButton": {
      "id": "front_of_house.waitlist.check_in_modal.button",
      "defaultMessage": "Check-in Party"
    },
    "frontOfHouseWaitlistCheckInModalTitle": {
      "id": "front_of_house.waitlist.check_in_modal.title",
      "defaultMessage": "Confirm Check-in"
    },
    "frontOfHouseWaitlistNotifyModalTitle": {
      "id": "front_of_house.waitlist.notify_modal.title",
      "defaultMessage": "Notify Guest"
    },
    "frontOfHouseWaitlistPartyNotified": {
      "id": "front_of_house.waitlist.party_notified",
      "defaultMessage": "Party Notified"
    },
    "frontOfHouseWaitlistSeatModalButton": {
      "id": "front_of_house.waitlist.seat_modal.button",
      "defaultMessage": "Seat Party"
    },
    "frontOfHouseWaitlistSeatModalTitle": {
      "id": "front_of_house.waitlist.seat_modal.title",
      "defaultMessage": "Confirm Seating"
    },
    "frontOfHouseWaitlistReAddModalButton": {
      "id": "front_of_house.waitlist.readd_modal.button",
      "defaultMessage": "Re-add Party"
    },
    "frontOfHouseWaitlistReAddModalTitle": {
      "id": "front_of_house.waitlist.readd_modal.title",
      "defaultMessage": "Confirm Party"
    },
    "frontOfHouseWaitlistTableIsReady": {
      "id": "front_of_house.waitlist.table_ready",
      "defaultMessage": "Table is Ready"
    },
    "frontOfHouseWaitlistWaiting": {
      "id": "front_of_house.waitlist.waiting",
      "defaultMessage": "Waiting"
    },
    "frontOfHouseWaitlistWait": {
      "id": "front_of_house.waitlist.wait",
      "defaultMessage": "Total Wait Time"
    },
    "frontOfHouseWaitlistAverageWaitTimeMin": {
      "id": "front_of_house.waitlist.average_wait_time.min",
      "defaultMessage": "min"
    },
    "frontOfHouseWaitlistAverageWaitTimeMins": {
      "id": "front_of_house.waitlist.average_wait_time.mins",
      "defaultMessage": "mins"
    },
    "frontOfHouseWaitlistAverageWaitTimeTitle": {
      "id": "front_of_house.waitlist.average_wait_time.title",
      "defaultMessage": "Average Wait Time"
    },
    "frontOfHouseWaitlistModalAddButton": {
      "id": "front_of_house.waitlist.modal.add_button",
      "defaultMessage": "Add To Waitlist"
    },
    "frontOfHouseWaitlistModalAddTitle": {
      "id": "front_of_house.waitlist.modal.add_title",
      "defaultMessage": "New Party"
    },
    "frontOfHouseWaitlistModalEditButton": {
      "id": "front_of_house.waitlist.modal.edit_button",
      "defaultMessage": "Save"
    },
    "frontOfHouseWaitlistModalEditTitle": {
      "id": "front_of_house.waitlist.modal.edit_title",
      "defaultMessage": "Modify Party"
    },
    "frontOfHouseWaitlistModalPartyOverview": {
      "id": "front_of_house.waitlist.modal.party_overview",
      "defaultMessage": "Party Overview"
    },
    "frontOfHouseWaitlistModalEmail": {
      "id": "front_of_house.waitlist.modal.email",
      "defaultMessage": "Email"
    },
    "frontOfHouseWaitlistModalMobileNumber": {
      "id": "front_of_house.waitlist.modal.mobile_number",
      "defaultMessage": "Mobile Number"
    },
    "frontOfHouseWaitlistModalName": {
      "id": "front_of_house.waitlist.modal.name",
      "defaultMessage": "Name"
    },
    "frontOfHouseWaitlistModalNotes": {
      "id": "front_of_house.waitlist.modal.notes",
      "defaultMessage": "Notes"
    },
    "frontOfHouseWaitlistModalPartySize": {
      "id": "front_of_house.waitlist.modal.party_size",
      "defaultMessage": "Party Size"
    },
    "frontOfHouseWaitlistModalQuoteTime": {
      "id": "front_of_house.waitlist.modal.quote_time",
      "defaultMessage": "Quote Time"
    },
    "frontOfHouseWaitlistModalQuoteTimeIncrement": {
      "id": "front_of_house.waitlist.modal.quote_time_increment",
      "defaultMessage": "Minutes"
    },
    "frontOfHouseWaitlistModalRemoveButton": {
      "id": "front_of_house.waitlist.modal.remove_button",
      "defaultMessage": "Remove Party"
    },
    "frontOfHouseWaitlistModalRemoveButtonConfirmTitle": {
      "id": "front_of_house.waitlist.modal.remove_button.confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "frontOfHouseWaitlistModalRemoveButtonConfirmText": {
      "id": "front_of_house.waitlist.modal.remove_button.confirm_text",
      "defaultMessage": "Remove party from waitlist?"
    },
    "frontOfHouseWaitlistModalSince": {
      "id": "front_of_house.waitlist.modal.since",
      "defaultMessage": "since"
    },
    "frontOfHouseWaitlistModalTimeWaited": {
      "id": "front_of_house.waitlist.modal.time_waited",
      "defaultMessage": "Time Waited"
    },
    "frontOfHouseWaitlistNavBack": {
      "id": "front_of_house.waitlist.nav_back",
      "defaultMessage": "Back"
    },
    "frontOfHouseWaitlistNavNext": {
      "id": "front_of_house.waitlist.nav_next",
      "defaultMessage": "Next"
    },
    "frontOfHouseWaitlistNotes": {
      "id": "front_of_house.waitlist.notes",
      "defaultMessage": "Notes"
    },
    "frontOfHouseWaitlistNoNotes": {
      "id": "front_of_house.waitlist.no_notes",
      "defaultMessage": "No available notes"
    },
    "frontOfHouseWaitlistPartyOf": {
      "id": "front_of_house.waitlist.party_of",
      "defaultMessage": "Party of"
    },
    "frontOfHouseWaitlistPartyOfCheckedIn": {
      "id": "front_of_house.waitlist.party_of.checked_in",
      "defaultMessage": "Checked in"
    },
    "frontOfHouseWaitlistPartyOfNotified": {
      "id": "front_of_house.waitlist.party_of.notified",
      "defaultMessage": "Notified"
    },
    "frontOfHouseWaitlistPartyOfReadded": {
      "id": "front_of_house.waitlist.party_of.seated",
      "defaultMessage": "Seated"
    },
    "frontOfHouseWaitlistPartyOfSeated": {
      "id": "front_of_house.waitlist.party_of.readded",
      "defaultMessage": "Added to Waitlist"
    },
    "frontOfHouseWaitlistPartyWaitingPlural": {
      "id": "front_of_house.waitlist.party_waiting.plural",
      "defaultMessage": "Parties Waiting"
    },
    "frontOfHouseWaitlistPartyWaitingSingular": {
      "id": "front_of_house.waitlist.party_waiting.singular",
      "defaultMessage": "Party Waiting"
    },
    "frontOfHouseWaitlistQuotedTime": {
      "id": "front_of_house.waitlist.quoted_time",
      "defaultMessage": "Quoted Time"
    },
    "frontOfHouseReservationsTitle": {
      "id": "front_of_house.reservations.title",
      "defaultMessage": "Reservations"
    },
    "frontOfHouseReservationsTabTitleToday": {
      "id": "front_of_house.reservations.tab_title.today",
      "defaultMessage": "Today",
    },
    "frontOfHouseReservationsTabTitleSeated": {
      "id": "front_of_house.reservations.tab_title.seated",
      "defaultMessage": "Seated",
    },
    "frontOfHouseReservationsTabTitleUpcoming": {
      "id": "front_of_house.reservations.tab_title.upcoming",
      "defaultMessage": "Upcoming",
    },
    "frontOfHouseReservationsTabTitleHistory": {
      "id": "front_of_house.reservations.tab_title.history",
      "defaultMessage": "History",
    },
    "frontOfHouseReservationsPartyOf": {
      "id": "front_of_house.reservations.party_of",
      "defaultMessage": "Party of"
    },
    "frontOfHouseReservationsPartyOfAccepted": {
      "id": "front_of_house.reservations.party_of.accepted",
      "defaultMessage": "Accepted"
    },
    "frontOfHouseReservationsPartyOfPending": {
      "id": "front_of_house.reservations.party_of.pending",
      "defaultMessage": "Pending"
    },
    "frontOfHouseReservationsPartyOfCancelled": {
      "id": "front_of_house.reservations.party_of.cancelled",
      "defaultMessage": "Cancelled"
    },
    "frontOfHouseReservationsPartyOfRejected": {
      "id": "front_of_house.reservations.party_of.rejected",
      "defaultMessage": "Rejected"
    },
    "frontOfHouseReservationsPartyOfSeated": {
      "id": "front_of_house.reservations.party_of.seated",
      "defaultMessage": "Seated"
    },
    "frontOfHouseReservationsAcceptModalButton": {
      "id": "front_of_house.reservations.accept_modal.button",
      "defaultMessage": "Accept"
    },
    "frontOfHouseReservationsRejectModalButton": {
      "id": "front_of_house.reservations.reject_modal.button",
      "defaultMessage": "Reject"
    },
    "frontOfHouseReservationActionButtonReAdd": {
      "id": "front_of_house.reservations.action_button.readd",
      "defaultMessage": "Re-Add"
    },
    "frontOfHouseReservationReAddModalButton": {
      "id": "front_of_house.reservations.readd_modal.button",
      "defaultMessage": "Re-add Reservation"
    },
    "frontOfHouseReservationUnseatModalButton": {
      "id": "front_of_house.reservations.unseat_modal.button",
      "defaultMessage": "Unseat Reservation"
    },
    "frontOfHouseReservationReAddModalTitle": {
      "id": "front_of_house.reservations.readd_modal.title",
      "defaultMessage": "Confirm Re-add"
    },
    "frontOfHouseReservationUnseatModalTitle": {
      "id": "front_of_house.reservations.unseat_modal.title",
      "defaultMessage": "Confirm Unseat"
    },
    "frontOfHouseReservationPartyOfSeated": {
      "id": "front_of_house.reservations.party_of.readded",
      "defaultMessage": "Added to Reservations"
    },
    "frontOfHouseReservationPartyOfUnseated": {
      "id": "front_of_house.reservations.party_of.unseated",
      "defaultMessage": "Unseated"
    },
    "frontOfHouseReservationsAcceptModalTitle": {
      "id": "front_of_house.reservations.accept_modal.title",
      "defaultMessage": "Confirm Acceptance"
    },
    "frontOfHouseReservationsRejectModalTitle": {
      "id": "front_of_house.reservations.reject_modal.title",
      "defaultMessage": "Confirm Rejection"
    },
    "frontOfHouseReservationsNavBack": {
      "id": "front_of_house.reservations.nav_back",
      "defaultMessage": "Back"
    },
    "frontOfHouseReservationsNavNext": {
      "id": "front_of_house.reservations.nav_next",
      "defaultMessage": "Next"
    },
    "frontOfHouseReservationZeroSearchResults": {
      "id": "front_of_house.reservations.zero_search_results",
      "defaultMessage": "Sorry, no parties found with that name"
    },
    "frontOfHouseReservationZeroStateText": {
      "id": "front_of_house.reservations.zero_state_text",
      "defaultMessage": "Add your Guests to the Reservation"
    },
    "frontOfHouseReservationsReservationTime": {
      "id": "front_of_house.reservations.reservation_time",
      "defaultMessage": "Reservation Time"
    },
    "frontOfHouseReservationNotes": {
      "id": "front_of_house.reservations.notes",
      "defaultMessage": "Notes"
    },
    "frontOfHouseReservationModalEmail": {
      "id": "front_of_house.reservations.modal.email",
      "defaultMessage": "Email"
    },
    "frontOfHouseReservationModalMobileNumber": {
      "id": "front_of_house.reservations.modal.mobile_number",
      "defaultMessage": "Mobile Number"
    },
    "frontOfHouseReservationModalName": {
      "id": "front_of_house.reservations.modal.name",
      "defaultMessage": "Name"
    },
    "frontOfHouseReservationModalPartySize": {
      "id": "front_of_house.reservations.modal.party_size",
      "defaultMessage": "Party Size"
    },
    "frontOfHouseReservationModalReservationDate": {
      "id": "front_of_house.reservations.modal.reservation_date",
      "defaultMessage": "Date"
    },
    "frontOfHouseReservationModalReservationTime": {
      "id": "front_of_house.reservations.modal.reservation_time",
      "defaultMessage": "Time"
    },
    "frontOfHouseReservationModalEditButton": {
      "id": "front_of_house.reservations.modal.edit_button",
      "defaultMessage": "Save"
    },
    "frontOfHouseReservationModalAddButton": {
      "id": "front_of_house.reservations.modal.add_button",
      "defaultMessage": "Add reservation"
    },
    "frontOfHouseReservationModalRemoveButtonConfirmTitle": {
      "id": "front_of_house.reservations.modal.remove_button.confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "frontOfHouseReservationModalRemoveButtonConfirmText": {
      "id": "front_of_house.reservations.modal.remove_button.confirm_text",
      "defaultMessage": "Remove party from reservations?"
    },
    "frontOfHouseReservationModalRemoveButton": {
      "id": "front_of_house.reservations.modal.remove_button",
      "defaultMessage": "Remove Party"
    },
    "frontOfHouseReservationsModalAddTitle": {
      "id": "front_of_house.reservations.modal.add_title",
      "defaultMessage": "Add Reservation"
    },
    "frontOfHouseReservationsModalEditTitle": {
      "id": "front_of_house.reservations.modal.edit_title",
      "defaultMessage": "Reservation details"
    },
    "frontOfHouseReservationSeatModalButton": {
      "id": "front_of_house.reservations.seat_modal.button",
      "defaultMessage": "Seat Party"
    },
    "frontOfHouseReservationSeatModalTitle": {
      "id": "front_of_house.reservations.seat_modal.title",
      "defaultMessage": "Confirm Seating"
    },
    "frontOfHouseReservationPastPartiesZeroStateText": {
      "id": "front_of_house.reservations.past_parties.zero_state_text",
      "defaultMessage": "No parties have been seated."
    },
    "frontOfHouseReservationPastPartiesZeroStateSubText": {
      "id": "front_of_house.reservations.past_parties.zero_state_sub_text",
      "defaultMessage": "This is where you’ll see a history view of all the parties that have been seated."
    },
    "frontOfHouseReservationPastPartiesPartySeatedTime": {
      "id": "front_of_house.reservations.past_parties.party_seated_time",
      "defaultMessage": "Seated Time"
    },
    "frontOfHouseReservationPastPartiesPartyAcceptedAt": {
      "id": "front_of_house.reservations.past_parties.reservation_seated_at",
      "defaultMessage": "Seated At"
    },
    "frontOfHouseReservationPastPartiesPartyRemovedAt": {
      "id": "front_of_house.reservations.past_parties.reservation_removed_at",
      "defaultMessage": "Removed At"
    },
    "frontOfHouseReservationPastPartiesPartyRejectedAt": {
      "id": "front_of_house.reservations.past_parties.reservation_rejected_at",
      "defaultMessage": "Rejected At"
    },
    "frontOfHouseReservationModalPartyOverview": {
      "id": "front_of_house.reservations.modal.party_overview",
      "defaultMessage": "Party Overview"
    },
    "frontOfHouseWaitlistTitle": {
      "id": "front_of_house.waitlist.title",
      "defaultMessage": "Waitlist"
    },
    "frontOfHouseWaitlistTabTitleWaitlist": {
      "id": "front_of_house.waitlist.tab_title.waitlist",
      "defaultMessage": "Waitlist",
    },
    "frontOfHouseWaitlistTabTitlePastParties": {
      "id": "front_of_house.waitlist.tab_title.past_parties",
      "defaultMessage": "Past Parties",
    },
    "frontOfHouseWaitlistZeroSearchResults": {
      "id": "front_of_house.waitlist.zero_search_results",
      "defaultMessage": "Sorry, no parties found with that name"
    },
    "frontOfHouseWaitlistSearchAriaLabel": {
      "id": "front_of_house.waitlist.search_aria_label",
      "defaultMessage": "Search guests"
    },
    "frontOfHouseWaitlistSearch": {
      "id": "front_of_house.waitlist.search_placeholder",
      "defaultMessage": "Search guests..."
    },
    "frontOfHouseReservationsDropdownAll": {
      "id": "front_of_house.reservations.dropdown.all",
      "defaultMessage": "All Reservations"
    },
    "frontOfHouseReservationsDropdownReserved": {
      "id": "front_of_house.reservations.dropdown.reserved_party_state",
      "defaultMessage": "Accepted Reservations"
    },
    "frontOfHouseReservationsDropdownPending": {
      "id": "front_of_house.reservations.dropdown.pending_party_state",
      "defaultMessage": "Pending Reservations"
    },
    "frontOfHouseWaitlistZeroStateText": {
      "id": "front_of_house.waitlist.zero_state_text",
      "defaultMessage": "Add your Guests to the Waitlist"
    },
    "frontOfHouseWaitlistPastPartiesZeroStateText": {
      "id": "front_of_house.waitlist.past_parties.zero_state_text",
      "defaultMessage": "No parties have been seated."
    },
    "frontOfHouseWaitlistPastPartiesZeroStateSubText": {
      "id": "front_of_house.waitlist.past_parties.zero_state_sub_text",
      "defaultMessage": "This is where you’ll see a history view of all the parties that have been seated."
    },
    "frontOfHouseWaitlistPastPartiesEmptyStateText": {
      "id": "front_of_house.waitlist.past_parties.empty_state_text",
      "defaultMessage": "No parties have been seated."
    },
    "frontOfHouseWaitlistPastPartiesEmptyStateSubText": {
      "id": "front_of_house.waitlist.past_parties.empty_state_sub_text",
      "defaultMessage": "This is where you’ll see a history view of all the parties that have been seated."
    },
    "frontOfHouseWaitlistPastPartiesTotalWaitTime": {
      "id": "front_of_house.waitlist.past_parties.total_wait_time",
      "defaultMessage": "Total Wait Time"
    },
    "frontOfHouseWaitlistPastPartiesPartySeatedAt": {
      "id": "front_of_house.waitlist.past_parties.party_seated_at",
      "defaultMessage": "Seated at"
    },
    "frontOfHouseWaitlistPastPartiesPartySeatedTime": {
      "id": "front_of_house.waitlist.past_parties.party_seated_time",
      "defaultMessage": "Seated Time"
    },
    "frontOfHouseWaitlistPastPartiesPartyEntryPoint": {
      "id": "front_of_house.waitlist.past_parties.entry_point",
      "defaultMessage": "Entry Point"
    },
    "frontOfHouseWaitlistPastPartiesPartyRemovedAt": {
      "id": "front_of_house.waitlist.past_parties.party_removed_at",
      "defaultMessage": "Party Removed at"
    },
    "frontOfHouseWaitlistEntryPointOriginAnsering": {
      "id": "front_of_house.waitlist.entry_point_origin.answering",
      "defaultMessage": "AI Answering"
    },
    "frontOfHouseWaitlistEntryPointOriginHost": {
      "id": "front_of_house.waitlist.entry_point_origin.host",
      "defaultMessage": "Host/Staff"
    },
    "frontOfHouseWaitlistEntryPointOriginQRCode": {
      "id": "front_of_house.waitlist.entry_point_origin.qr_code",
      "defaultMessage": "QR Code"
    },
    "frontOfHouseWaitlistEntryPointOriginWebsite": {
      "id": "front_of_house.waitlist.entry_point_origin.website",
      "defaultMessage": "Website"
    },
    "frontOfHouseWaitlistEntryPointOriginUnknown": {
      "id": "front_of_house.waitlist.entry_point_origin.unknown",
      "defaultMessage": "Entry Point Unknown"
    },
    "frontOfHouseFeedbackTitle": {
      "id": "front_of_house.feedback.title",
      "defaultMessage": "Feedback"
    },
  }
);
export default defaultMessages;
