import {
  SET_ORDERING_OFFER_ID,
  SET_AUDIENCE_FILTERS,
} from './TextMessageGroupsActions';

const initialState = {
  orderingOfferId: null,
};

const textMessageGroups = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERING_OFFER_ID:
      return {
        ...state,
        orderingOfferId: action.orderingOfferId,
      };
    case SET_AUDIENCE_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    default:
      return state;
  }
};

export default textMessageGroups;
