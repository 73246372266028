export const SET_ORDERING_OFFER_ID = 'SET_ORDERING_OFFER_ID';
export const SET_AUDIENCE_FILTERS = 'SET_AUDIENCE_FILTERS';

export function setOrderingOfferId(orderingOfferId) {
  return (dispatch) => {
    dispatch({
      orderingOfferId,
      type: SET_ORDERING_OFFER_ID,
    });
  };
}

export function setAudienceFilters(filters) {
  return (dispatch) => {
    dispatch({
      filters,
      type: SET_AUDIENCE_FILTERS,
    });
  };
}
